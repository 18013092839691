<template>
    <main class="main">
        <nav class="breadcrumb-nav">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <router-link to="/shop/sidebar/list">Shop</router-link>
                    </li> -->
                    <li class="breadcrumb-item active">{{ $t('pay') }}</li>
                </ol>
            </div>
        </nav>

        <div class="page-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="text-primary">{{ $t('pay') }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <CheckoutDetails />
                    </div>
                    <div class="col-md-4">
                        <CheckoutShippingComponent :isShippingInvalid="form.shippingInvalid"
                            :isPaymentInvalid="form.paymentInvalid" />
                    </div>
                    <div class="col-md-4">
                        <OrderOverview @invalidateShipping="form.shippingInvalid = true"
                            @invalidatePayment="form.paymentInvalid = true" />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import CheckoutDetails from "@/esf_weert_mobilesupplies/core/components/partial/Checkout/CheckoutDetails.vue"
import CheckoutShippingComponent from "@/esf_weert_mobilesupplies/core/components/partial/Checkout/CheckoutShippingComponent.vue"
import OrderOverview from "@/esf_weert_mobilesupplies/core/components/partial/Checkout/OrderOverview.vue"
import Cart from "@storefront/core/modules/cart/mixins";
// import Pagination from '@/esf_weert_mobilesupplies/core/components/elements/ProductPagination';
// import { checkEmailExist } from '@storefront/core/data-resolver/user';

export default {
    mixins: [Cart],
    components: {
        CheckoutDetails,
        CheckoutShippingComponent,
        OrderOverview
    },
    data: function () {
        return {
            form: {
                shippingInvalid: false,
                paymentInvalid: false,
            },
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["user/getIsLoggedIn"];
        },
        shippingMethod() {
            return this.$store.getters["cart/getShippingMethod"];
        },
        paymentMethod() {
            return this.$store.getters["cart/getPaymentMethod"];
        },
    },
    watch: {
        shippingMethod(newValue) {
            if (newValue !== null) this.form.shippingInvalid = false;
        },
        paymentMethod(newValue) {
            if (newValue !== null) this.form.paymentInvalid = false;
        },
    },
};
</script>