<template>
    <div>
        <div class="checkout-card mb-2">
            <h6 class="heading text-primary">
                <font-awesome-icon icon="fa-solid fa-truck" />
                {{ $t('shipping_method') }}
            </h6>
            <p>{{ $t('fill_address_information') }}</p>

            <div class="accordion-summary mb-0">
                <!-- <div v-for="(deliveryOption, index) of deliveryOptions" :key="`ship-${index}`" class="custom-radio">
                    <input type="radio" name="radio-group" v-model="selectedShippingMethod" :id="`ship-${index}`"
                        :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
                        class="custom-control-input" />
                    <label :for="`ship-${index}`" class="custom-control-label">
                        <div class="payment-icon">
                            {{ formatCurrency(deliveryOption.amount.value) }}
                        </div>
                        &nbsp;
                        <span>
                            {{ deliveryOption.carrier_title }} ({{
                    deliveryOption.method_title
                }})
                        </span>
                    </label>
                </div> -->

                <div class="card" v-for="(deliveryOption, index) of deliveryOptions" :key="`ship-${index}`">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button"
                                :class="{ expanded: shippingMethod[index], collapsed: !shippingMethod[index] }"
                                @click.prevent="changeShippingMethod(index, deliveryOption)" href="#">{{
                    formatCurrency(deliveryOption.amount.value) }} &nbsp;
                                <span>
                                    {{ deliveryOption.carrier_title }} ({{
                    deliveryOption.method_title
                }})
                                </span></a>
                        </h2>
                    </div>
                </div>

                <!-- <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button"
                                :class="{ expanded: shippingMethod[1], collapsed: !shippingMethod[1] }"
                                @click.prevent="changeShippingMethod(1)" href="#">0,00 Afhalen in onze
                                winkel</a>
                        </h2>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- v-if="selectedShippingMethod != null" -->
        <div class="checkout-card mb-2 mb-md-0">
            <h6 class="heading text-primary">
                <font-awesome-icon icon="fa-solid fa-credit-card" />
                {{ $t('payment_method') }}
            </h6>
            <p>{{ $t('fill_address_information') }}</p>
            <div v-if="selectedShippingMethod != null">
                <div class="accordion-summary" v-if="paymentOptions && paymentOptions.length > 0">
                    <div class="card" v-for="(paymentOption, index) in paymentOptions" :key="index">
                        <div class="card-header">
                            <h2 class="card-title">
                                <a class="toggle-button d-flex align-items-center gap-1"
                                    :class="{ expanded: paymentMethod[index], collapsed: !paymentMethod[index] }"
                                    @click.prevent="changePaymentMethod(index, paymentOption, paymentOption.code)"
                                    href="#">
                                    <img class="mt-0"
                                        :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                                        width="35" :alt="paymentOption.code" />
                                    <span>{{ paymentOption.label }}</span>
                                </a>
                            </h2>
                        </div>
                        <vue-slide-toggle :open="paymentMethod[index]">
                            <template v-if="hasSelectedPaymentSubOptions">
                                <div class="card-body">
                                    <div class="form-group" v-for="(
                                        paymentSubOption, index
                                    ) in paymentOption.additional_fields" :key="`pso-${index}`"
                                        :ref="'subOptions-' + paymentOption.code">
                                        <div v-if="paymentSubOption.type == 'select'">
                                            <label v-if="paymentOption.label !== 'iDEAL'">{{
                    paymentSubOption.label
                }}</label>
                                            <label v-else>{{ $t("pay_securely_through_your_own_bank") }}</label>
                                            <b-form-group class="select--custom">
                                                <b-form-select :options="paymentSubOption.options" class="form-control"
                                                    text-field="label" :value="paymentMethodOptions[paymentOption.code][
                    paymentSubOption.code
                ]
                    " v-on:change="
                    setPaymentMethodOptions(
                        paymentOption.code,
                        paymentSubOption.code,
                        $event
                    )
                    ">
                                                    <template #first>
                                                        <b-form-select-option :value="null">{{
                                                            $t("select_bank")
                                                            }}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                            </b-form-group>
                                            <!-- <select type="pay_securely" class="form-control" id="pay_securely"
                                            name="pay_securely" required>
                                            <option value="kies uw bank">kies uw bank</option>
                                        </select> -->
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </vue-slide-toggle>
                    </div>

                    <!-- <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[0], collapsed: !paymentMethod[0] }"
                                @click.prevent="changePaymentMethod(0)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/ideal.png"
                                    alt="" />
                                <span>iDEAL</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[0]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[1], collapsed: !paymentMethod[1] }"
                                @click.prevent="changePaymentMethod(1)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/bancontact.png"
                                    alt="" />
                                <span>Bancontact / Mr Cash (België)</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[1]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[2], collapsed: !paymentMethod[2] }"
                                @click.prevent="changePaymentMethod(2)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/KBC_Bank.png"
                                    alt="" />
                                <span>KBC / CBC (België)</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[2]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[3], collapsed: !paymentMethod[3] }"
                                @click.prevent="changePaymentMethod(3)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/belfius.png"
                                    alt="" />
                                <span>Belfius (België)</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[3]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[4], collapsed: !paymentMethod[4] }"
                                @click.prevent="changePaymentMethod(4)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/paypal.png"
                                    alt="" />
                                <span>PayPal</span>
                                <small class="float-right paypal-link">What is PayPal?</small>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[4]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[5], collapsed: !paymentMethod[5] }"
                                @click.prevent="changePaymentMethod(5)" href="#">
                                <img class="mt-0"
                                    src="@/esf_weert_mobilesupplies/assets/static/images/payment-methods-icon/visa.png"
                                    alt="" />
                                <span>Creditcard (Visa / Mastercard)</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[5]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            <a class="toggle-button d-flex align-items-center gap-1"
                                :class="{ expanded: paymentMethod[6], collapsed: !paymentMethod[6] }"
                                @click.prevent="changePaymentMethod(6)" href="#">
                                <span>{{ $t("advance_payment_by_bank_transfer") }}</span>
                            </a>
                        </h2>
                    </div>

                    <vue-slide-toggle :open="paymentMethod[6]">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="pay_securely">{{ $t('pay_securely_through_your_own_bank')
                                    }}</label>
                                <select type="pay_securely" class="form-control" id="pay_securely" name="pay_securely"
                                    required>
                                    <option value="kies uw bank">kies uw bank</option>
                                </select>
                            </div>
                        </div>
                    </vue-slide-toggle>
                </div> -->
                </div>
            </div>
        </div>
        <!-- <div v-else class="note hide">
        {{ $t("fill_shipping_options") }}
      </div> -->
    </div>
</template>
<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import config from "@config";

export default {
    props: {
        isShippingInvalid: Boolean,
        isPaymentInvalid: Boolean,
    },
    components: {
        VueSlideToggle
    },
    data() {
        return {
            app_url: config.app_url,
            shippingMethod: [true, false],
            paymentMethod: [true, false, false, false, false, false, false, false, false, false, false, false],
            hasSelectedPaymentSubOptions: false,
        };
    },
    computed: {
        deliveryOptions() {
            return this.$store.getters["cart/getShippingMethods"];
        },
        paymentMethodOptions() {
            return this.$store.getters["cart/getPaymentMethodOptions"];
        },
        paymentOptions: {
            get() {
                var options = this.$store.getters["cart/getPaymentMethods"];
                if (options == null) {
                    this.$store.commit("setShippingMethod", this.selectedShippingMethod);
                }
                return options;
            },
            set() { },
        },
        selectedShippingMethod: {
            get: function () {
                const shmed = this.$store.getters["cart/getShippingMethod"];
                if (shmed != null) {
                    return shmed.carrier_code + "|" + shmed.method_code;
                } else {
                    return null;
                }
            },
            set: function (value) {
                if (value !== null) {
                    this.paymentOptions = this.$store.getters["cart/getPaymentMethods"];
                }
                const delOption = value.split("|");
                this.$store.dispatch("cart/addShippingMethod", {
                    carrier: delOption[0],
                    method: delOption[1],
                });
                if (this.$gtm.enabled()) {
                    let prod = [];
                    this.cartItems.forEach((element) => {
                        let i = {
                            id: element.product.sku,
                            name: element.product.name,
                            quantity: element.quantity,
                        };
                        prod.push(i);
                    });
                    window.dataLayer.push({
                        event: "eec.checkout",
                        ecommerce: {
                            checkout: {
                                actionField: {
                                    step: 3,
                                    option: value,
                                    action: "checkout",
                                },
                                products: prod,
                            },
                        },
                    });
                }
            },
        },
        selectedPaymentMethod: {
            get: function () {
                return this.$store.getters["cart/getPaymentMethod"];
            },
            set: function (value) {
                this.$store.commit("cart/setPaymentMethod", value);
                if (this.$gtm.enabled()) {
                    let prod = [];
                    this.cartItems.forEach((element) => {
                        let i = {
                            id: element.product.sku,
                            name: element.product.name,
                            quantity: element.quantity,
                        };
                        prod.push(i);
                    });
                    window.dataLayer.push({
                        event: "eec.checkout",
                        ecommerce: {
                            checkout: {
                                actionField: {
                                    step: 2,
                                    option: value,
                                    action: "checkout",
                                },
                                products: prod,
                            },
                        },
                    });
                }
            },
        }
    },
    mounted() {
        this.$root.$on("checkFields", () => {
            this.checkFields();
        });
    },
    watch: {
        selectedDeliveryOption() {
            if (this.selectedDeliveryOption != null) {
                const delOption = this.selectedDeliveryOption.split("|");
                this.$store.dispatch("cart/addShippingMethod", {
                    carrier: delOption[0],
                    method: delOption[0],
                });
            }
        },
        selectedPaymentMethod: {
            handler: function (method) {
                if (method === "multisafepay_ideal") {
                    this.hasSelectedPaymentSubOptions = true;
                } else this.hasSelectedPaymentSubOptions = false;
            },
            immediate: true,
        },
        address() {
            if (this.paymentOptions == []) {
                this.$store.commit("setShippingMethod", this.selectedShippingMethod);
                this.paymentOptions = this.$store.getters["cart/getPaymentMethods"];
            }
        },
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        changePaymentMethod: function (index1, paymentOption, code) {
            this.paymentMethod = this.paymentMethod.reduce((acc, cur, index) => {
                if (index == index1) return [...acc, !cur];
                return [...acc, false];
            }, []);
            this.selectedPaymentMethod = code;
            this.checkAllSubOptions(paymentOption);
        },
        changeShippingMethod: function (index1, deliveryOption) {
            this.shippingMethod = this.shippingMethod.reduce((acc, cur, index) => {
                if (index == index1) return [...acc, cur];
                return [...acc, false];
            }, []);
            this.selectedShippingMethod = `${deliveryOption.carrier_code}|${deliveryOption.method_code}`
        },
        checkFields() {
            var errorFields = [];
            if (!this.shippingMethod) {
                errorFields.push(this.$t("shipping_method"));
            }
            if (!this.selectedPaymentMethod) {
                errorFields.push(this.$t("payment_method"));
            } else if (this.selectedPaymentMethod == "multisafepay_ideal") {
                if (
                    !this.paymentMethodOptions.multisafepay_ideal ||
                    !this.paymentMethodOptions.multisafepay_ideal.issuer_id
                ) {
                    errorFields.push(this.$t("Bank"));
                }
            }
            if (errorFields.length > 0) {
                this.$store.commit("cart/setStoreValidation", {
                    payment: errorFields,
                });
            } else {
                this.$store.commit("cart/setStoreValidation", {
                    payment: [],
                });
            }
        },
        checkAllSubOptions(paymentOption) {
            if (
                paymentOption &&
                paymentOption.code &&
                paymentOption.code === "multisafepay_ideal"
            ) {
                this.hasSelectedPaymentSubOptions = true;
            } else this.hasSelectedPaymentSubOptions = false;
        },
        setPaymentMethodOptions(key, sub, value) {
            this.$store.commit("cart/updateCartPaymentMethodOptions", {
                key: key,
                sub: sub,
                value: value,
            });
        },
    }
}
</script>