<template>
    <div class="product-checkout">
        <div class="product-checkout-image">
            <img :src="product.product.thumbnail.large" alt="">
        </div>
        <div class="product-checkout-meta">
            <p class="title" :title="product.product.name">{{ productName }}</p>
            <quantity-input :product="product" @change-qty="changeQty" class="mt-1"></quantity-input>
        </div>
        <div class="product-checkout-action">
            <div class="d-flex flex-column align-items-end">
                <font-awesome-icon icon="fa-solid fa-trash-alt" class="text-primary" />
                <p class="price">{{ formatCurrency(product.product.price_range.maximum_price.final_price.value) }}</p>
            </div>
        </div>

        <!-- badges -->
        <!-- <div class="product-checkout-badges">
            <span class="product-checkout-label label-out" v-if="product.stock_qty === 0">{{ $t('out_of_stock') }}</span>
            <span class="product-checkout-label label-top" v-if="product.top">{{ $t('top') }}</span>
            <span class="product-checkout-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
            <span class="product-checkout-label label-new" v-if="product.new">{{ $t('new') }}</span>
        </div> -->
        <!-- badges -->
    </div>
</template>

<script>
import QuantityInput from '@/esf_weert_mobilesupplies/core/components/elements/QuantityInput';

export default {
    props: {
        product: Object
    },
    data() {
        return {
            qty: 1,
            nameMaxLength: 20
        }
    },
    components: {
        QuantityInput,
    },
    computed: {
        productName() {
            if (this.product.product.name.length <= this.nameMaxLength) {
                return this.product.product.name;
            } else {
                const words = this.product.product.name.split(' ');
                let shortenedString = '';
                let count = 0;
                for (let i = 0; i < words.length; i++) {
                    if (count + words[i].length <= this.nameMaxLength) {
                        shortenedString += words[i] + ' ';
                        count += words[i].length;
                    } else {
                        break;
                    }
                }
                return shortenedString.trim() + '...';
            }
        }
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        changeQty: function (current) {
            this.qty = current;
        },
    }
}
</script>