<template>
    <div>
        <div class="checkout-card">
            <h6 class="heading text-primary">
                <font-awesome-icon icon="fa-solid fa-eye" />
                {{ $t('order_overview') }}
            </h6>
            <div class="accordion" id="accordion-1" v-if="cartItems && cartItems.length > 0">
                <div class="card">
                    <div class="card-header" id="products">
                        <h5 class="card-title">
                            <a href="#" class="toggle-button"
                                :class="{ expanded: toggleState[0][0], collapsed: !toggleState[0][0] }"
                                @click.prevent="changeToggle(0, 0)">{{ $t('item_in_shopping_baset', {
                    count: cartCount
                }) }}</a>
                        </h5>
                    </div>
                    <vue-slide-toggle :open="toggleState[0][0]">
                        <div class="card-body pr-3">
                            <template>
                                <div class="row" v-for="cartItem of cartItems" :key="cartItem.id">
                                    <div class="col-12">
                                        <product-checkout :product="cartItem"></product-checkout>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </vue-slide-toggle>
                </div>
            </div>
            <p class="text-dark"><u><strong>Toon meer</strong></u></p>
            <hr class="my-1">
            <p class="my-3">Overzicht van bestelling</p>
            <hr class="my-1">
            <div class="d-flex align-items-center justify-content-between">
                <div class="my-1">
                    <p class="text-dark"><strong>Winkelmandje subtotaal</strong></p>
                </div>
                <div class="my-1">
                    <p class="text-dark text-right">
                        <strong>{{ formatCurrency(cartSubTotalPrice) }}</strong>
                    </p>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between"
                v-if="cartTotalTaxPrices && cartTotalTaxPrices.length > 0">
                <div class="my-1">
                    <p class="text-dark"><strong>BTW 21%</strong></p>
                </div>
                <div class="my-1">
                    <p class="text-dark text-right"><strong>{{
                    formatCurrency(cartTotalTaxPrices[0].amount.value) }}</strong></p>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between" v-if="shippingMethod != null">
                <div class="my-1">
                    <p class="text-dark"><strong>{{ $t('shipping_cost') }}</strong></p>
                </div>
                <div class="my-1">
                    <p class="text-dark text-right"><strong>{{
                    formatCurrency(shippingMethod.amount_including_tax.value) }}</strong>
                    </p>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between" v-else>
                <div class="my-1">
                    <p class="text-dark"><strong>{{ $t('shipping_cost') }}</strong></p>
                </div>
                <div class="my-1">
                    <p class="text-dark text-right"><strong>{{ formatCurrency(0) }}</strong></p>
                </div>
            </div>
        </div>
        <!-- <hr class="my-1"> -->
        <div class="d-flex align-items-center justify-content-between my-2 px-3">
            <div class="my-1">
                <h6 class="text-dark my-2"><strong>{{ $t("total") }}</strong></h6>
            </div>
            <div class="my-1">
                <h6 class="text-dark text-right my-2"><strong>{{ formatCurrency(cartTotalPrice)
                        }}</strong></h6>
            </div>
        </div>
        <div class="accordion mb-0" id="accordion-1">
            <div class="card">
                <div class="card-header" id="heading-1">
                    <h5 class="card-title">
                        <a href="#" class="toggle-button"
                            :class="{ expanded: toggleState[0][1], collapsed: !toggleState[0][1] }"
                            @click.prevent="changeToggle(0, 1)">{{ $t('use_discount_code') }}</a>
                    </h5>
                </div>

                <vue-slide-toggle :open="toggleState[0][1]">
                    <div class="card-body pr-3">
                        <div class="newsletter-popup-container">
                            <div class="input-group input-group-round m-0 w-100">
                                <input type="text" class="form-control form-control-white" v-model="couponCode"
                                    required />
                                <div class="input-group-append">
                                    <button class="btn" type="button" @click="addCoupon">
                                        <span>{{ $t('add') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="card">
                <div class="card-header" id="heading-2">
                    <h5 class="card-title">
                        <a class="toggle-button" :class="{ expanded: toggleState[0][2], collapsed: !toggleState[0][2] }"
                            @click.prevent="changeToggle(0, 2)" href="#">{{ $t('add_a_comment_to_your_order') }}</a>
                    </h5>
                </div>

                <vue-slide-toggle :open="toggleState[0][2]">
                    <div class="card-body pr-3">
                        <form action="#" class="p-0">
                            <div class="form-group">
                                <textarea class="form-control" name="vivamus" id="vivamus" cols="30" rows="2"
                                    v-model="customerNote"
                                    @change="addCustomerNote"></textarea>
                            </div>
                            <div class="form-group text-right">
                                <button class="btn btn-dark">
                                    Toevoegen
                                </button>
                            </div>
                        </form>
                    </div>
                </vue-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- <form action="#"> -->
                <div class="custom-control custom-checkbox" v-if="!hideNewsLetter">
                    <input type="checkbox" class="custom-control-input" id="nieuwsbrief" v-model="SubscribeNewsletter"
                        value="yes" unchecked-value="no" />
                    <label class="custom-control-label" for="nieuwsbrief">{{
                    $t("subscription_newsletter") }}</label>
                </div>
                <p>
                    Bij het plaatsen van een bestelling gaat u akkoord met onze <span class="text-success"> ALGEMENE
                        VOORWAARDEN, PRIVACY
                        VERKLARING</span> en verklaart u uw bestelling en gegevens te hebben
                    gecontroleerd.
                </p>
                <div class="row">
                    <div class="col-md-12 text-center mt-2">
                        <button @click="submitForm" :disabled="buttonClicked"
                            class="btn btn-success w-75">{{ $t('pay') }}</button>
                    </div>
                </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</template>


<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import ProductCheckout from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductCheckout';
import Cart from "@storefront/core/modules/cart/mixins";
import config from "@config";

export default {
    mixins: [Cart],
    components: {
        VueSlideToggle,
        ProductCheckout
    },
    data() {
        return {
            hideNewsLetter: false,
            toggleState: [
                [true, false, false],
            ],
            couponCode: "",
            customerNote: "",
            SubscribeNewsletter: "no",
            buttonClicked: false,
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["user/getIsLoggedIn"];
        },
        shippingMethod() {
            return this.$store.getters["cart/getShippingMethod"];
        },
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        async addCoupon() {
            if (this.couponCode != "") {
                const retval = await this.$store.dispatch("cart/addCouponCode", {
                    code: this.couponCode,
                });
                if (retval == true) {
                    const msg = {
                        type: "success",
                        title: this.$t("coupon_code"),
                        text: this.$t("coupon_code_ok", { code: this.couponCode }),
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                    this.couponCode = "";
                } else {
                    const msg = {
                        type: "danger",
                        title: "invalid coupon code",
                        text: "please enter valid coupon code",
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                }
            }
        },
        async addCustomerNote() {
            this.$store.commit("cart/setComment", this.customerNote);
        },
        async submitForm() {
            this.checkSiblingFields();
            if (this.checkFields()) {
                this.buttonClicked = true;
                const getShipping = this.$store.getters["cart/getShippingMethod"];
                const getPayment = this.$store.getters["cart/getPaymentMethod"];
                const getPaymentMethodOptions =
                    this.$store.getters["cart/getPaymentMethodOptions"];

                if (getShipping == null) {
                    this.$emit("invalidateShipping");
                } else if (getPayment == null) {
                    this.$emit("invalidatePayment");
                } else {
                    if (getPayment === "multisafepay_ideal") {
                        if (
                            !getPaymentMethodOptions ||
                            !getPaymentMethodOptions[getPayment] ||
                            !getPaymentMethodOptions[getPayment].issuer_id
                        ) {
                            return this.$emit("invalidatePayment");
                        }
                    }
                    const retval = await this.$store.dispatch("cart/addPaymentMethod");
                    localStorage.removeItem("orderHash");
                    if (retval.order.hash) {
                        localStorage.setItem("orderHash", retval.order.hash);
                    }
                    if (retval != false) {
                        const url = retval.redirect_url.replace(config.app_url, "/");
                        if (this.isLoggedIn == true) {
                            this.$store.dispatch("user/loadOrders");
                        }
                        this.$store.dispatch("cart/unLoad");
                        if (url.includes("https://")) {
                            window.location = url;
                        } else {
                            this.$store.dispatch("cart/loadCart");
                            this.$router.push(
                                url + "?transactionid=" + retval.order.increment_id
                            );
                        }
                    }
                }
                this.buttonClicked = false;
            }
        },
        checkFields() {
            var errorFields = [];
            var storeValidation = this.$store.getters["cart/getStoreValidation"];
            for (const property in storeValidation) {
                storeValidation[property].forEach((error) => {
                    errorFields.push(error);
                });
            }
            if (errorFields.length > 0) {
                const msg = {
                    type: "danger",
                    title: this.$t("Checkout fields incomplete"),
                    text: this.$t("The following fields are invalid:", {
                        errors: errorFields.join(", "),
                    }),
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                return false;
            }
            return true;
        },
        checkSiblingFields() {
            this.$root.$emit("checkFields");
        },
        changeToggle: function (index1, index2) {
            this.toggleState = this.toggleState.reduce((acc1, cur1, id1) => {
                if (id1 == index1) {
                    let newStates = cur1.reduce((acc2, cur2, id2) => {
                        if (id2 == index2) return [...acc2, !cur2];
                        else return [...acc2, false];
                    }, []);

                    return [...acc1, newStates];
                } else {
                    return [...acc1, cur1];
                }
            }, []);
        }
    },
    mounted() {
        this.$root.$on("subscribe_newsletter", (value) => {
            if (this.SubscribeNewsletter !== value) {
                this.SubscribeNewsletter = value;
            }
        });
        if (this.isLoggedIn) {
            var user = this.$store.getters["user/getCurrentUser"];
            this.hideNewsLetter = user.is_subscribed;
        }
    },
    watch: {
        SubscribeNewsletter(value) {
            this.$root.$emit("subscribe_newsletter", value);
        },
    },
}
</script>