<template>
    <CheckoutComponent />
</template>
<script>
import CheckoutComponent from "@/esf_weert_mobilesupplies/core/components/partial/Checkout/CheckoutComponent.vue";
import config from "@config";

export default {
    components: {
        CheckoutComponent,
    },
    metaInfo() {
        return {
            title: this.$t("order"),
            link: [
                {
                    rel: "canonical",
                    href:
                        config.app_url +
                        (/\/$/.test(this.$route.fullPath)
                            ? this.$route.fullPath
                            : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>